import styled from "styled-components";

export const ModalWrapper = styled.div`
width: 100%;

& > div {
  margin: auto;
  max-width: 720px;
  width: 100%;
  background:  ${({ theme }) => theme.palatte.background.light};
  display: flex;
      border: 1px solid ${({ theme }) => theme.palatte.text.default};
      flex-direction: column;
      align-items: center;
  
    .contact-title {
      display: flex;
      width: calc(100% - 48px);
      justify-content: space-between;
      padding: 16px 24px;
      align-items: center;

      border-bottom: 1px solid ${({ theme }) => theme.palatte.text.default};

      .close-button {
        position: relative;
        height: 32px;
        cursor: pointer;
        width: 32px;

        span {
          top: 16px;
          display: block;
          position: absolute;
          height: 2px;
          width: 32px;
          background: ${({ theme }) => theme.palatte.text.default};
       
        }
        span:nth-child(1) {
          transform: rotate(135deg);
        }
      
        span:nth-child(2) {
          transform: rotate(45deg);
        }
      }
    }

    .input-wrapper {
      width: calc(100% - 48px);
      max-width: 520px;
      padding: 16px 24px 0px 24px;

      & > p {
        font-size: 16px;
        margin: 0px 0px 12px 0px;
      }

      & > span {
        font-size: 12px;
        color: ${({ theme }) => theme.palatte.text.highlight};
      }

      & > input.error {
        border: 1px solid ${({ theme }) => theme.palatte.text.highlight};
      }

      & > input {
        border: 1px solid ${({ theme }) => theme.palatte.text.default};
        height: 38px;
        width: calc(100% - 16px);
        padding-left: 16px;
        font-size: 16px;
        max-width: 520px;
      }

      & > textarea {
        border: 1px solid ${({ theme }) => theme.palatte.text.default};
        height: 102px;
        width: calc(100% - 32px);
        max-width: 520px;
        font-size: 16px;
        padding: 8px 16px;
      }

      & > .input-checkbox {
        padding-bottom: 12px; 
        display: flex;
        align-items: center;
      }
      & > .input-checkbox > div {
        width: 32px;
        margin-right: 8px;
      }
      & > .input-checkbox > div > input {
        height: 32px;
        width: 32px;
      }
      & > .input-checkbox > input:checked {
        background-color: ${({ theme }) => theme.palatte.text.default};
      }
      & > .input-checkbox > label {
        font-size: 16px;
        margin-left: 8px;
        word-break: break-word;
      }
    }

    & > button:hover {
      cursor: pointer;
    }
    & > button:active {
      background-color: ${({ theme }) => theme.palatte.text.contrast};
      color: ${({ theme }) => theme.palatte.text.default};
    }

    & > button {
      padding: 16px 24px;
      margin: 32px;
      color: ${({ theme }) => theme.palatte.text.contrast};
      border: none;
      background-color: ${({ theme }) => theme.palatte.text.default};
      font-size: 16px;
    }

    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: ${({ theme }) => theme.palatte.text.default};
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
`;

export const ModalCentered = styled.div`
  position: fixed;
  z-index: 2000;
  max-height: 100vh;
  width: 100%;
  height: 100vh;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  background: rgba(0,0,0,0.4);
  overflow-y: auto;

  @media (max-width: 600px) {
    padding-top: 0px;
  }
`

export const Modal = styled.div`
  position: fixed;
  z-index: 2000;
  max-height: calc(100vh - 80px);
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  // height: 100vh;
  top: 0px;
  bottom: 0px;
  background: rgba(0,0,0,0.4);
  overflow-y: auto;

  @media (max-width: 600px) {
    padding-top: 0px;
  }
`

export const SuccessModalWrapper = styled.div`
width: 100%;

& > div {
  margin: auto;
  max-width: 320px;
  width: 100%;
  background:  ${({ theme }) => theme.palatte.background.light};
  display: flex;
  border: 1px solid ${({ theme }) => theme.palatte.text.default};
  flex-direction: column;
  align-items: center;
  padding: 16px;

  & > .progress {
    width: 100%;
    height: 3px;
    border: 1px solid ${({ theme }) => theme.palatte.text.default};
    background-color:  ${({ theme }) => theme.palatte.text.default};
    overflow: hidden;

    & > span {
      width: 0%;
      display: block;
      height: 3px;
      background-color:  ${({ theme }) => theme.palatte.background.contrast};
      transition: 3.5s linear;
      animation: progressAnimationStrike 3.5s;
    }
  }

  @keyframes progressAnimationStrike {
    from { width: 0 }
    to   { width: 100% }
  }


  & > p {
    padding: 0px;
    line-height: 1.5;
    margin-top: 0px;
  }

  & > button {
      padding: 10px 32px;
      margin: 8px;
      color: ${({ theme }) => theme.palatte.text.contrast};
      border: none;
      background-color: ${({ theme }) => theme.palatte.text.default};
      font-size: 16px;
    }
}
`